<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="基础合同信息">
          <el-col v-if="!viewEdit()" :span="12">
            <el-form-item label="买方企业名称">
              <p>{{ utils.isEffectiveCommon(appForm.buyFirmName) }}</p>
            </el-form-item>
          </el-col>
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item prop="buyFirmId" label="买方企业名称">
              <ics-item-inner :prop="appForm.buyFirmId" :format="utils.isEffectiveCommon">
                <el-select v-model="appForm.buyFirmId" placeholder="请选择买方企业" filterable @change="(val)=>companyChange(val)">
                  <el-option v-for="item in coreCustomerList" :key="item.coreId" :label="`${item.coreName}（${item.coreCode}）`" :value="item.coreId" />
                </el-select>
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="卖方企业名称">
              <p>{{ utils.isEffectiveCommon(appForm.sellFirmName) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="买方统一社会信用代码">
              <p>{{ utils.isEffectiveCommon(appForm.buyFirmSocialNo) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="卖方统一社会信用代码">
              <p>{{ utils.isEffectiveCommon(appForm.sellFirmSocialNo) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="买方法人姓名">
              <p>{{ utils.isEffectiveCommon(appForm.buyLegalPerson) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="卖方法人姓名">
              <p>{{ utils.isEffectiveCommon(appForm.sellLegalPerson) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="partition-area">
              <el-table :data="appForm.contractList" border class="table-input">
                <el-table-column label="基础合同编号" label-class-name="required-th" min-width="130" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-form-item v-if="viewEdit()" :prop="`contractList.${scope.$index}.contracNo`" :rules="rules.contracNo" label-width="0">
                      <el-input v-model="scope.row.contracNo" placeholder="请输入基础合同编号" />
                      <template slot="error" slot-scope="errScope">
                        <table-form-error-tooltip :err-scope="errScope" />
                      </template>
                    </el-form-item>
                    <el-form-item v-else label-width="0">
                      <span>{{ utils.isEffectiveCommon(scope.row.contracNo) }}</span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="合同名称" label-class-name="required-th" min-width="130" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-form-item v-if="viewEdit()" :prop="`contractList.${scope.$index}.contracName`" :rules="rules.contracName" label-width="0">
                      <el-input v-model="scope.row.contracName" placeholder="请输入合同名称" />
                      <template slot="error" slot-scope="errScope">
                        <table-form-error-tooltip :err-scope="errScope" />
                      </template>
                    </el-form-item>
                    <el-form-item v-else label-width="0">
                      <span>{{ utils.isEffectiveCommon(scope.row.contracName) }}</span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="基础合同金额(元)" label-class-name="required-th" min-width="130" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-form-item v-if="viewEdit()" :prop="`contractList.${scope.$index}.contracAmount`" :rules="rules.contracAmount" label-width="0">
                      <el-input v-model="scope.row.contracAmount" type="number" placeholder="请输入基础合同金额" />
                      <template slot="error" slot-scope="errScope">
                        <table-form-error-tooltip :err-scope="errScope" />
                      </template>
                    </el-form-item>
                    <el-form-item v-else label-width="0">
                      <span>{{ utils.isEffectiveCommon(scope.row.contracAmount) }}</span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="签订时间" label-class-name="required-th" min-width="130" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-form-item v-if="viewEdit()" :prop="`contractList.${scope.$index}.signTime`" :rules="rules.signTime" label-width="0">
                      <ics-search-date :date.sync="scope.row.signTime" type="date" style="width: 100%" />
                      <template slot="error" slot-scope="errScope">
                        <table-form-error-tooltip :err-scope="errScope" />
                      </template>
                    </el-form-item>
                    <el-form-item v-else label-width="0">
                      <span>{{ utils.isEffectiveCommon(scope.row.signTime) }}</span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="合同到期时间" label-class-name="required-th" min-width="130" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-form-item v-if="viewEdit()" :prop="`contractList.${scope.$index}.dueTime`" :rules="rules.dueTime" label-width="0">
                      <ics-search-date :date.sync="scope.row.dueTime" type="date" style="width: 100%" />
                      <template slot="error" slot-scope="errScope">
                        <table-form-error-tooltip :err-scope="errScope" />
                      </template>
                    </el-form-item>
                    <el-form-item v-else label-width="0">
                      <span>{{ utils.isEffectiveCommon(scope.row.dueTime) }}</span>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="发票信息">
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item label="关联发票" label-width="100px">
              <el-button type="primary" @click="relationInvoice">
                选择发票
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="partition-area">
              <el-table :data="appForm.invoicesList" border style="width: 100%" class="table-input" max-height="250">
                <el-table-column prop="invoiceCode" label="发票编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
                <el-table-column prop="invoiceKind" label="发票类型" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceKindType')" show-overflow-tooltip min-width="100" />
                <el-table-column prop="buyerTaxName" label="采购方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                <el-table-column prop="sellerTaxName" label="销售方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                <el-table-column prop="billingDate" label="开票时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                <el-table-column prop="totalAmountTax" label="金额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
                <el-table-column prop="totalTax" label="税额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
                <el-table-column prop="invoiceStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceStatus')" show-overflow-tooltip min-width="150" />
                <el-table-column v-if="viewEdit() && this.appForm.invoicesList.length > 0" label="操作" align="center" fixed="right" show-overflow-tooltip min-width="60">
                  <template v-slot="scope">
                    <el-link type="danger" class="text-btn" @click="delInvoice(scope.$index)">
                      移除
                    </el-link>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="附件">
          <el-col :span="24">
            <el-form-item label="合同附件" prop="filesList" label-width="120px">
              <p v-if="viewEdit()">
                <debounce-button type="primary" @click="clickDialog">
                  点击上传
                </debounce-button>
              </p>
              <p v-for="(item, index) in appForm.filesList" :key="index">
                <a href="javascript:" class="text-btn" @click="utils.downloadP('other', item.url)">{{ item.name }}</a>
                <a v-if="viewEdit()" href="javascript:" class="text-btn danger" @click="deleteFile(index)"><i class="el-icon-delete" /></a>
              </p>
            </el-form-item>
          </el-col>
          <ics-button-inner v-if="viewEdit()" :loading="loading.submit" submit-title="提交" @submit="submitForms" />
        </ics-page-inner>
      </el-form>
    </div>
    <ics-dialog-inner width="60%" :visible.sync="dialog.visible" :title="dialog.title" submit-title="提交" cancel-title="取消" class="form-dialog" @submit="confirmInvoice">
      <div class="partition-area">
        <search-inner :search-id="1" :search-form="searchInvoiceForm" :fold-btn="true" @submit-search="searchInvoice" @clear-search="resetInvoice">
          <template>
            <el-col :span="6">
              <el-form-item label="采购方：">
                <el-input v-model="searchInvoiceForm.buyerTaxName" placeholder="请输入要搜索的核心企业名称" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开票时间：">
                <el-date-picker v-model="searchInvoiceForm.billingArr" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" />
              </el-form-item>
            </el-col>
          </template>
        </search-inner>
        <div class="list-btn-inner" />
        <table-inner ref="multipleTable" v-loading="loadingInvoice.list" :table-header="false" :table-data="invoiceInfo.list" :page-num="invoiceInfo.pageNum" :page-size="invoiceInfo.pageSize" :pages="invoiceInfo.pages" :page-id="invoiceInfo.pageId" @changePageNum="changeInvoicePageNum" @selection-change="handleSelectionChange">
          <template slot="table-columns">
            <el-table-column type="selection" :selectable="setSelection" align="left" min-width="50" />
            <el-table-column prop="invoiceCode" label="发票编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
            <el-table-column prop="invoiceKind" label="发票类型" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceKindType')" show-overflow-tooltip min-width="100" />
            <el-table-column prop="buyerTaxName" label="采购方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="sellerTaxName" label="销售方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="billingDate" label="开票时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="totalAmountTax" label="金额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
            <el-table-column prop="totalTax" label="税额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
            <el-table-column prop="invoiceStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceStatus')" show-overflow-tooltip min-width="150" />
          </template>
        </table-inner>
      </div>
    </ics-dialog-inner>
    <ics-common-upload-inner :upload-dialog="dialog.upload" bucket="other" @onSuccessFile="onSuccessFile" />
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsSearchDate from '@/components/search-date'
import IcsDialogInner from '@/components/dialog-inner'
import IcsCommonUploadInner from '@/components/common-upload-inner'
export default {
  components: { IcsSearchDate, IcsDialogInner, IcsCommonUploadInner },
  mixins: [routeEnterMixin],
  data () {
    return {
      fileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/customer/b/p/access/info/upload?token=' + this.$store.state.token,
      contractId: this.$route.query.contractId,
      appForm: {
        buyFirmId: '',
        buyFirmName: '',
        buyFirmSocialNo: '',
        buyLegalPerson: '',
        sellFirmId: '',
        sellFirmName: '',
        sellFirmSocialNo: '',
        sellLegalPerson: '',
        contractList: [{
          contracNo: '',
          contracName: '',
          contracAmount: '',
          signTime: '',
          dueTime: '',
        }],
        invoicesList: [],
        filesList: []
      },
      customerDetail: {},
      coreCustomerList: [],
      dialog: {
        visible: false,
        title: '',
        upload: {
          visible: false,
          title: '上传附件'
        }
      },
      loadingInvoice: {
        list: false
      },
      searchInvoiceForm: {
        buyerTaxName: '',
        billingArr: [],
        billingDateStart: '',
        billingDateEnd: ''
      },
      selectInvoice: '',
      multipleSelection: undefined,
      flag: false,
      invoiceInfo: {
        list: []
      },
      rules: {
        buyFirmId: [
          this.rulesToolkit.rules.required({ name: '买方企业', trigger: 'change' })
        ],
        contracNo: this.changeRules({ name: '合同编号', required: true }),
        contracName: this.changeRules({ name: '合同名称', required: true }),
        contracAmount: this.changeRules({ name: '基础合同金额', required: true }),
        signTime: [
          this.rulesToolkit.rules.required({ name: '签订时间', trigger: 'change' })
        ],
        dueTime: [
          this.rulesToolkit.rules.required({ name: '合同到期时间', trigger: 'change' })
        ],
        filesList: [
          { required: true, message: '请上传合同附件', trigger: 'change' }
        ],
      }
    }
  },
  created () {
    if (this.contractId) {
      this.getDetail()
    }
    if (this.viewEdit()) {
      this.getCustomerData()
      this.getCoreCustomerList()
    }
  },
  methods: {
    getDetail () {
      this.api.assets.contract.contractDetail(this.contractId).then(result => {
        const data = result.data.data || {}
        data.contractList = [{
          contracNo: data.contracNo,
          contracName: data.contracName,
          contracAmount: data.contracAmount,
          signTime: data.signTime,
          dueTime: data.dueTime
        }]
        this.appForm = data
        console.log(this.appForm)
      }).finally(() => {
      })
    },
    getCustomerData () {
      this.api.company.customer.getCustomerDetail().then(result => {
        const data = result.data.data.firm || {}
        data.sellFirmId = data.id
        data.sellFirmName = data.companyName
        data.sellFirmSocialNo = data.socialNo
        data.sellLegalPerson = data.legalPerson
        const { sellFirmId, sellFirmName, sellFirmSocialNo, sellLegalPerson } = data
        this.appForm = this._.assign(this.appForm, { sellFirmId, sellFirmName, sellFirmSocialNo, sellLegalPerson })
      }).finally(() => {
      })
    },
    getCoreCustomerList () {
      this.api.company.customer.getCoreCustomerList().then(result => {
        this.coreCustomerList = result.data.data || []
      }).finally(() => {
      })
    },
    companyChange (val) {
      const data = this._.find(this.coreCustomerList, { coreId: val }) || {}
      data.buyFirmName = data.coreName
      data.buyFirmSocialNo = data.coreSocialNo
      data.buyLegalPerson = data.legalPerson
      const { buyFirmName, buyFirmSocialNo, buyLegalPerson } = data
      this.appForm = this._.assign(this.appForm, { buyFirmName, buyFirmSocialNo, buyLegalPerson })
    },
    relationInvoice () {
      this.dialog.title = '选择发票'
      this.dialog.visible = true
      this.getInvoiceList({})
    },
    // 跳页回显已选择的
    setSelection (row, index) {
      const isCheck = this._.find(this.appForm.invoicesList, (o) => {
        return o.id === row.id
      })
      return !isCheck
    },
    getInvoiceList (searchForm) {
      this.loadingInvoice.list = true
      searchForm.invoiceStatus = 'N'
      if (!searchForm.pageNum) {
        searchForm.pageNum = 1
      }
      searchForm.pageSize = 10
      this.api.assets.invoice.listPage(this.utils.filterTrimValue(searchForm)).then(result => {
        this.invoiceInfo = result.data.data
      }).finally(() => {
        this.loadingInvoice.list = false
      })
    },
    searchInvoice () {
      if (this.searchInvoiceForm.billingArr) {
        this.searchInvoiceForm.billingDateStart = this.searchInvoiceForm.billingArr[0]
        this.searchInvoiceForm.billingDateEnd = this.searchInvoiceForm.billingArr[1]
      }
      const searchForm = {
        pageNum: 1,
        buyerTaxName: this.searchInvoiceForm.buyerTaxName,
        billingDateStart: this.searchInvoiceForm.billingDateStart,
        billingDateEnd: this.searchInvoiceForm.billingDateEnd
      }
      this.getInvoiceList(searchForm)
    },
    resetInvoice () {
      this.searchInvoiceForm.buyerTaxName = ''
      this.searchInvoiceForm.billingArr = ''
      this.getInvoiceList(this.searchInvoiceForm)
    },
    changeInvoicePageNum (val) {
      this.searchInvoiceForm.pageNum = val.pageNum
      this.getInvoiceList(this.searchInvoiceForm)
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    confirmInvoice () {
      if (this.multipleSelection === undefined) {
        this.$message.error('请选择发票')
        return false
      }
      this.multipleSelection.forEach(item => {
        this.appForm.invoicesList.push(item)
      })
      this.dialog.visible = false
    },
    delInvoice (index) {
      this.appForm.invoicesList.splice(index, 1)
    },
    clickDialog () {
      this.dialog.upload.visible = true
    },
    onSuccessFile (response) {
      response.data.url = response.data.path
      if (this.appForm.filesList.length > 5) {
        this.$message.error('最多能上传6个文件')
        return false
      }
      this.appForm.filesList.push(response.data)
    },
    deleteFile (index) {
      this.appForm.filesList.splice(index, 1)
    },
    previewFile (file) {
      this.utils.downloadFile(file.url, file.name)
    },
    submitForms () {
    }
  }
}
</script>

<style scoped>

</style>
